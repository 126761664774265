<template>
  <page title="我的" no-back>
    <div class="p-4 bg-white flex items-start">
      <media avatar-size='60' :src="TestData.avatar" class="mr-3"></media>
      <div class="flex-1 overflow-hidden">
        <div class="font-medium">李刚的儿子</div>
        <div class="mt-2 text-xs text-gray-light">积分：9999</div>
        <div class="flex mt-2">
          <tag color="red">平台商户</tag>
        </div>
      </div>
      <btn color="primary" size="sm" text-size="xs" outline>成为推广员</btn>
    </div>
    <div class="mt-3">
      <cell note="34" icon="icon-trust" url="/promote-code/">我的推广码</cell>
      <cell note="34" icon="icon-coupons" url="/my-order/">我的订单</cell>
      <cell note="34" icon="icon-trust" url="/my-extension/">我的推广</cell>
      <cell note="23" icon="icon-discounts" url="/my-commission">我的佣金</cell>
      <cell note="23" icon="icon-wallet" url="/commission-withdraw">佣金提现</cell>
      <cell note="23" icon="icon-consumption" url="/my-bankcard">我的银行卡</cell>
      <cell note="11" icon="icon-component" url="/my-score">我的积分</cell>
      <cell note="9" icon="icon-comments" url="/message">我的消息</cell>
      <cell icon="icon-integral" url="/my-profile">我的资料</cell>
      <cell note="4" icon="icon-map" url="/my-address" no-hairline>收货地址</cell>
    </div>
    <div class="mt-3">
      <cell icon="icon-company" url="/platform-info/">平台介绍</cell>
      <cell icon="icon-bussiness-man" url="/online-service/" no-hairline>在线客服</cell>
    </div>
    <div class="p-4">
      <btn color="red-400" @click="loggedOut" block>退出登录</btn>
    </div>
  </page>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import { CardItem } from '@/components'
export default {
  components: {
    CardItem
  },
  computed: {
    ...mapGetters([
      'TestData'
    ]),
    ...mapState([
      'userInfo'
    ]),
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions([
      'aLogin'
    ]),
    loggedOut() {
      this.aLogin(false)
      this.$router.push('/login')
    }
  },
  created() {
    
  }
}
</script>

<style>

</style>